<template>
    <uw-content title="团队管理" padding="10px">

        <!-- 菜单 -->
        <template slot="suffix">
            <el-button type="primary" size="mini" @click="$refs.drawerCreate.Open()">新增团队</el-button>
        </template>

        <!-- 表格 -->
        <uw-table :show-header="false">

            <!-- 数据 -->
            <template slot="data">
                <vxe-table ref="xTable" :data="table" height="100%" @sort-change="TableSort">
                    <vxe-column width="60" field="id" title="ID" sortable></vxe-column>

                    <vxe-column width="160" field="name" title="团队名称" sortable>
                        <template #default="{ row }">
                            <div style="display: flex; align-items: center;">
                                <svg class="w-icon" aria-hidden="true" font-size="14px">
                                    <use :xlink:href="row.icon"></use>
                                </svg>
                                <span class="w-margin-l-10" style="font-size: 12px;"><b>{{ row.name }}</b></span>
                            </div>
                        </template>
                    </vxe-column>

                    <vxe-column width="120" field="user_leader_id" title="团队负责人" sortable>
                        <template #default="{ row }">
                            <el-link icon="el-icon-user-solid">{{ row.user_leader.name }}</el-link>
                        </template>
                    </vxe-column>

                    <vxe-column min-width="280" field="user_members_id" title="团队成员" sortable>
                        <template #default="{ row }">
                            <el-link icon="el-icon-user">{{ row.user_members_id.length }} 人：</el-link>
                            <el-link class="w-margin-r-5" v-for="(item, idx) in row.user_members" :key="idx">{{ item.name }}</el-link>
                        </template>
                    </vxe-column>

                    <vxe-column width="140" field="created_at" title="团队成立日期" sortable></vxe-column>
                    <vxe-column width="140" field="updated_at" title="团队更新日期" sortable></vxe-column>

                    <vxe-column width="130" title="管理" fixed="right">
                        <template #default="{ row }">
                            <el-link type="primary" icon="el-icon-edit-outline" class="w-margin-r-10" @click="$refs.drawerUpdate.Open(row.id)">修改</el-link>
                            <el-link type="danger" icon="el-icon-delete" @click="TableDelete(row.id)">移除</el-link>
                        </template>
                    </vxe-column>
                </vxe-table>
            </template>

            <!-- 分页 -->
            <template slot="pager">
                <vxe-pager :total="page.total" :page-size.sync="page.size" :current-page.sync="page.current" @page-change="TableSearch()"></vxe-pager>
            </template>
            
        </uw-table>

        <drawerCreate ref="drawerCreate" @success="TableSearch"></drawerCreate>
        <drawerUpdate ref="drawerUpdate" @success="TableSearch"></drawerUpdate>
    </uw-content>
</template>

<script>
import drawerCreate from "@/views/config/drawerUsersTeamCreate"
import drawerUpdate from "@/views/config/drawerUsersTeamUpdate"

export default {

    components: {
        drawerCreate,
        drawerUpdate
    },

    data () {
        return {
            loading: false,

            table: [],

            // 分页
            page: {
                total: 0,
                size: 50,
                current: 1,
            },

            // 检索
            search: {
            },

            // 排序
            order: {
                method: 'desc',
                column: 'id'
            },
        }
    },

    mounted() {
        this.TableSearch()
    },

    methods: {

        /**
        |--------------------------------------------------
        | 表格数据 - 查询
        |--------------------------------------------------
        |
        */

        TableSearch() {
            this.$http.post('/9api/users-team/search', { order: this.order, page: this.page, append: ['user_leader', 'user_members'] }).then(rps => {
                this.table = rps.data.body
                this.page.total = rps.data.total

                // 页码溢出
                if (this.table.length == 0 && this.page.current != 1) {
                    this.page.current = 1
                    this.TableSearch()
                }
            })
        },

        /**
        |--------------------------------------------------
        | 表格数据 - 排序
        |--------------------------------------------------
        |
        */

        TableSort() {

        },

        /**
        |--------------------------------------------------
        | 表格数据 - 移除
        |--------------------------------------------------
        |
        */

        TableDelete(id) {
            this.$http.post('/9api/users-team/delete', { id: id }).then(rps => {
                this.TableSearch()
            })
        },
    }
}
</script>