<!---------------------------------------------------------------------------- 
 | 用户中心 - 团队管理 - 创建
 |----------------------------------------------------------------------------
 | 方法：请注意大小写,参数描述见具体方法
 | $refs[ref_name].Open() 手动打开窗口,
 | $refs[ref_name].Close() 手动关闭窗口
 |----------------------------------------------------------------------------
 | 回调
 | @success = fn (Object) => {}
 |
 ---------------------------------------------------------------------------->

<template>
    <el-drawer class="w-el-drawer" :visible.sync="visible" :size="960" :with-header="false" direction="rtl" :before-close="Close" append-to-body>
        
        <!-- header -->
        <div class="w-el-drawer-header">
            <i class="el-icon-tickets"></i> 新增团队
        </div>

        <!-- body -->
        <div class="w-el-drawer-body"  v-loading="loading">
           
            <!-- 表单 -->
            <el-form :model="form" label-width="120px" size="mini">

                <el-divider content-position="left">团队徽章</el-divider>

                <el-form-item>
                    <template slot="label">
                        <inputIcon class="w-margin-l-20" v-model="form.icon" size="32px" />
                    </template>
                </el-form-item>

                <el-divider content-position="left">团队信息</el-divider>

                <!-- 团队名称 -->
                <el-form-item :rules="[{ required: true, message: '必填项' }]" label="团队名称" :error="formError.name?.toString()" prop="name">
                    <el-input v-model="form.name" placeholder="请输入团队名称"></el-input>
                </el-form-item>

                <!-- 团队负责人 -->
                <el-form-item :rules="[{ required: true, message: '必填项' }]" label="团队负责人" :error="formError.user_leader_id?.toString()" prop="user_leader_id">
                    <users-select v-model="form.user_leader_id" />
                </el-form-item>

                <!-- 团队成员 -->
                <el-form-item label="团队成员">
                    <users-select v-model="form.user_members_id" multiple />
                </el-form-item>

                <!-- 团队描述 -->
                <el-form-item label="团队描述">
                    <el-input v-model="form.describe" type="textarea" :rows="5" placeholder="团队描述"></el-input>
                </el-form-item>
            </el-form>
        </div>

        <!-- footer -->
        <div class="w-el-drawer-footer">
            <el-button size="medium" @click="Close()">取消 / 返回</el-button>
            <el-button size="medium" type="primary" @click="FormSubmit()" :loading="loading">创建</el-button>
        </div>

    </el-drawer>
</template>
    
<script>
import inputIcon from '@/components/input/inputIcon'

export default {
    components: {
        inputIcon,
    },

    data() {
        return {
            visible: false,
            loading: false,

            formError: {},
            form: {
                icon: null,
                name: null,
                user_leader_id: null,
                user_members_id: null,
                describe: null,
            }


        }
    },
    
    methods: {
        /**
        |--------------------------------------------------
        | 窗口 - 打开
        |--------------------------------------------------
        |
        */

        Open() {
            this.visible = true
            this.loading = false
        },

        
        /**
        |--------------------------------------------------
        | 窗口 - 关闭
        |--------------------------------------------------
        |
        */

        Close() {
            this.visible = false
            this.loading = false
        },
        
        /**
        |--------------------------------------------------
        | 表单提交
        |--------------------------------------------------
        |
        */

        FormSubmit() {
            // 清空错误提示
            this.loading = true
            this.formError = {}

            this.$http.post('/9api/users-team/create', this.form).then(rps => {
                
                this.loading = false

                if (rps.data.state == 'error') {
                    this.formError = rps.data.body
                }

                if (rps.data.state == 'success') {
                    this.$notify.success({
                        title: '提示',
                        message: '团队创建成功',
                        duration: 2000
                    })
                    this.$emit("success")
                    this.Close()
                }
            })
        }
    }
}
</script>